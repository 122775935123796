import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL, VENDOR_PRODUCT_URL } from "../../Constants/Config";

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getVendorProducts: builder.mutation({
      query: (data) => ({
        url: VENDOR_PRODUCT_URL,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useGetVendorProductsMutation } = productApi;
