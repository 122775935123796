import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  ADD_ADDRESS_URL,
  ALL_ADDRESS_URL,
  API_URL,
  CUSTOMER_UPDATE_PROFILE_URL,
  DELETE_ADDRESS_URL,
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  POST_CONTACT_URL,
  REGISTER_URL,
  RESET_PASSWORD_URL,
  SET_ADDRESS_URL,
  toastConfig,
  UPDATE_ADDRESS_URL,
  UPLOAD_PROFILE_URL,
  VERIFY_OTP_URL,
} from "../../Constants/Config";
import { toast } from "react-toastify";
import {
  callAddress,
  callIsAuth,
  callProfile,
  callRemoveAddress,
} from "../Slices/AuthSlice";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  tagTypes: ["Address"],
  endpoints: (builder) => ({
    registerCustomer: builder.mutation({
      query: (data) => ({
        url: REGISTER_URL,
        method: "POST",
        body: data,
      }),
      onQueryStarted: async (arg, api) => {
        const { queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error("Error in user registration.", toastConfig);
        } else {
          toast.success("User registration successfull.", toastConfig);
        }
      },
    }),
    loginCustomer: builder.mutation({
      query: (data) => ({
        url: LOGIN_URL,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Address"],
      onQueryStarted: async (arg, api) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          dispatch(callIsAuth(true));
          dispatch(callProfile(data.result));
          toast.success("Login successfull.", toastConfig);
        }
      },
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: FORGOT_PASSWORD_URL,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Address"],
      onQueryStarted: async (arg, api) => {
        const { queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          toast.success(data.message, toastConfig);
        }
      },
    }),
    verifyOTP: builder.mutation({
      query: (data) => ({
        url: VERIFY_OTP_URL,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Address"],
      onQueryStarted: async (arg, api) => {
        const { queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          toast.success(data.message, toastConfig);
        }
      },
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: RESET_PASSWORD_URL,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Address"],
      onQueryStarted: async (arg, api) => {
        const { queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          toast.success(data.message, toastConfig);
        }
      },
    }),
    getAddress: builder.mutation({
      query: function (customer_id) {
        return {
          url: ALL_ADDRESS_URL,
          method: "POST",
          body: { customer_id },
        };
      },
      providesTags: ["Address"],
      onQueryStarted: async (arg, api) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          //dispatch(callIsAuth(true));
          //dispatch(callProfile(data.result));
          dispatch(callAddress(data.result));
          //toast.success("Login successfull.", toastConfig);
        }
      },
    }),
    addAddress: builder.mutation({
      query: (data) => ({
        url: ADD_ADDRESS_URL,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Address"],
      onQueryStarted: async (arg, api) => {
        const { queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          toast.success("Address added successfull.", toastConfig);
        }
      },
    }),
    setAddress: builder.mutation({
      query: function (address) {
        return {
          url: SET_ADDRESS_URL,
          method: "POST",
          body: { id: address.id, customer_id: address.customer_id },
        };
      },
      invalidatesTags: ["Address"],
      onQueryStarted: async (arg, api) => {
        const { queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          toast.success("Address selected successfull.", toastConfig);
        }
      },
    }),
    deleteAddress: builder.mutation({
      query: function (address) {
        return {
          url: DELETE_ADDRESS_URL,
          method: "POST",
          body: { id: address.id },
        };
      },
      invalidatesTags: ["Address"],
      onQueryStarted: async (arg, api) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          dispatch(callRemoveAddress(arg?.id));
          toast.success("Address deleted successfull.", toastConfig);
        }
      },
    }),
    updateAddress: builder.mutation({
      query: function (address) {
        return {
          url: UPDATE_ADDRESS_URL,
          method: "POST",
          body: address,
        };
      },
      invalidatesTags: ["Address"],
      onQueryStarted: async (arg, api) => {
        const { queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          toast.success("Address updated successfull.", toastConfig);
        }
      },
    }),
    postContact: builder.mutation({
      query: (data) => ({
        url: POST_CONTACT_URL,
        method: "POST",
        body: data,
      }),
      onQueryStarted: async (arg, api) => {
        const { queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          toast.success(data.message, toastConfig);
        }
      },
    }),
    updateCustomerProfile: builder.mutation({
      query: (data) => ({
        url: CUSTOMER_UPDATE_PROFILE_URL,
        method: "POST",
        body: data,
      }),
      onQueryStarted: async (arg, api) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          dispatch(callProfile(data.result));
          toast.success("Profile Updated.", toastConfig);
        }
      },
    }),
    customerImage: builder.mutation({
      query: (file) => {
        const body = new FormData();
        body.append("Content-Type", file.type);
        body.append("image", file);
        return {
          url: UPLOAD_PROFILE_URL,
          method: "POST",
          body: body,
        };
      },
      onQueryStarted: async (arg, api) => {
        //const { dispatch, queryFulfilled } = api;
        //const { data } = await queryFulfilled;
      //   if (data.status !== 1) {
      //     toast.error(data.message, toastConfig);
      //   } else {
      //     dispatch(callProfile(data.result));
      //     //toast.success("Profile Image updated.", toastConfig);
      //   }
      },
    }),
  }),
});

export const {
  useRegisterCustomerMutation,
  useLoginCustomerMutation,
  useGetAddressMutation,
  useAddAddressMutation,
  useSetAddressMutation,
  useDeleteAddressMutation,
  useUpdateAddressMutation,
  usePostContactMutation,
  useForgotPasswordMutation,
  useVerifyOTPMutation,
  useResetPasswordMutation,
  useUpdateCustomerProfileMutation,
  useCustomerImageMutation
} = authApi;
