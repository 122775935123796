import React, { useCallback } from "react";
import Footer from "../components/Footer";
import TopAndSideBar from "../components/TopAndSideBar";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../Store/Store";
import { useLocation, useNavigate } from "react-router-dom";
import { useUpdateAddressMutation } from "../Store/api/AuthApi";
//import { useAddAddressMutation } from "../Store/api/AuthApi";

const addressSchema = Yup.object().shape({
  customer_name: Yup.string().required("Enter cutomer name."),
  phone: Yup.number()
    .min(10, "Please enter correct mobile number.")
    .required("Mobile number is required."),
  email: Yup.string()
    .email("Please enter valid email.")
    .required("Email address is required."),
  address: Yup.string().required("Adrees address is required."),
  landmark: Yup.string().required("Landmark address is required."),
  city: Yup.string().required("City is required."),
  state: Yup.string().required("State is required."),
  pincode: Yup.number().required("Pincode is required."),
  type: Yup.string().required("Address type is required."),
});

export default function UpdateAddress() {
  const navigate = useNavigate();
  const location = useLocation();

  const address = useSelector(
    (state: RootState) => state.auth.addresses[location?.state?.key]
  );

  const [updateAddress] = useUpdateAddressMutation();
  //const profile = useSelector((state: RootState) => state.auth.profile);
  //const [addAddress] = useAddAddressMutation();

  const onClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <TopAndSideBar />
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area space-pt--5 space-pb--25">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title">Update Address</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="auth-page-body-1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Auth form */}
              <div className="auth-form p-3">
                <Formik
                  initialValues={{
                    id: address?.id,
                    customer_id: address?.customer_id,
                    customer_name: address?.customer_name,
                    phone: address?.phone,
                    email: address?.email,
                    address: address?.address,
                    landmark: address?.landmark,
                    city: address?.city,
                    state: address?.state,
                    pincode: address?.pincode,
                    type: address?.type,
                  }}
                  validationSchema={addressSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    updateAddress(values).then((data) => {
                      setSubmitting(false);
                      onClose();
                    });
                  }}
                >
                  {function ({
                    handleSubmit,
                    isValid,
                    errors,
                    touched,
                    handleChange,
                    values,
                    isSubmitting,
                    setSubmitting,
                  }) {
                    return (
                      <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="col-md-6">
                          <label htmlFor="customer_name" className="form-label">
                            Customer Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="customer_name"
                            name="customer_name"
                            onChange={handleChange}
                            value={values.customer_name}
                            placeholder="Customer Name"
                            autoComplete="true"
                          />
                          {errors.customer_name && touched.customer_name && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.customer_name}
                            </small>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="phone" className="form-label">
                            Phone Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={handleChange}
                            value={values.phone}
                            placeholder="Phone Number"
                            autoComplete="true"
                          />
                          {errors.phone && touched.phone && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.phone}
                            </small>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            placeholder="Email"
                            autoComplete="true"
                          />
                          {errors.email && touched.email && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.email}
                            </small>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="address" className="form-label">
                            Address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            onChange={handleChange}
                            value={values.address}
                            placeholder="Address"
                            autoComplete="true"
                          />
                          {errors.address && touched.address && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.address}
                            </small>
                          )}
                        </div>
                        <div className="col-12">
                          <label htmlFor="landmark" className="form-label">
                            Landmark
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="landmark"
                            name="landmark"
                            onChange={handleChange}
                            value={values.landmark}
                            placeholder="Landmark"
                            autoComplete="true"
                          />
                          {errors.landmark && touched.landmark && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.landmark}
                            </small>
                          )}
                        </div>
                        <div className="col-12">
                          <label htmlFor="city" className="form-label">
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            onChange={handleChange}
                            value={values.city}
                            placeholder="City name"
                            autoComplete="true"
                          />
                          {errors.city && touched.city && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.city}
                            </small>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="state" className="form-label">
                            State
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="state"
                            name="state"
                            onChange={handleChange}
                            value={values.state}
                            placeholder="State name"
                            autoComplete="true"
                          />
                          {errors.state && touched.state && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.state}
                            </small>
                          )}
                        </div>
                        <>
                          <div className="col-md-2">
                            <label htmlFor="pincode" className="form-label">
                              Pincode
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="pincode"
                              name="pincode"
                              onChange={handleChange}
                              value={values.pincode}
                              placeholder="Pincode"
                              autoComplete="true"
                            />
                            {errors.pincode && touched.pincode && (
                              <small
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {errors.pincode}
                              </small>
                            )}
                          </div>
                          <div className="col-4">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="home"
                                name="type"
                                value={"Home"}
                                onChange={handleChange}
                                checked={values?.type === "Home"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="home"
                              >
                                Home
                              </label>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="office"
                                name="type"
                                value={"Office"}
                                onChange={handleChange}
                                checked={values?.type === "Office"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="office"
                              >
                                Office
                              </label>
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="other"
                                name="type"
                                value={"Other"}
                                onChange={handleChange}
                                checked={values?.type === "Other"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="other"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                          {errors.type && touched.type && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.type}
                            </small>
                          )}
                        </>
                        <div className="col-12">
                          {/* <button type="submit" className="btn btn-primary">
                            Sign in
                          </button> */}
                          <button
                            className="auth-form__button checkout-button"
                            disabled={isSubmitting}
                            type="submit"
                          >
                            {isSubmitting ? (
                              <div
                                className="spinner-border text-light"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
