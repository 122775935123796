import React, { useCallback, useState } from "react";
import TopAndSideBar from "../components/TopAndSideBar";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../Store/Store";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import * as Yup from "yup";
import format from "date-fns/format";
import {
  useCustomerOrdersMutation,
  usePlaceOrderMutation,
} from "../Store/api/OrderApi";

const orderSchema = Yup.object().shape({
  pickup_date: Yup.string().required("Pickup date is required."),
  order_note: Yup.string().required("Order note is required."),
  address_id: Yup.number().required("Please select address."),
});

export default function Checkout() {
  const [startDate, setStartDate] = useState(new Date());

  const address = useSelector((state: RootState) =>
    state.auth.addresses.find((item) => item.selected === 1)
  );
  const orderData = useSelector((state: RootState) => state.order.orderData);
  const cartData = useSelector((state: RootState) => state.cart);
  const customer_id = useSelector((state: RootState) => state.auth.profile.id);
  //const vendor_id = useSelector((state: RootState) => state.vendor.vendor.id);
  const vendor_id = 0;
  const [placeOrder] = usePlaceOrderMutation();
  const [customerOrder] = useCustomerOrdersMutation();

  const navigate = useNavigate();

  const onValueSet = useCallback(
    async (selDate, fieldsFunc) => {
      await setStartDate(selDate);
      let fdate = await format(selDate, "yyyy-MM-dd", {
        awareOfUnicodeTokens: true,
      });
      await fieldsFunc("pickup_date", fdate);
    },
    [setStartDate]
  );

  const onPlaceOrder = useCallback(
    (checkoutData, setSubmitting) => {
      let orData = { ...orderData, ...checkoutData, payment_mode: 1 };
      orData.customer_id = customer_id;
      orData.vendor_id = vendor_id;
      orData.promo_id = 0;
      orData.discount = 0;
      orData.payment_mode = 1;
      orData.prescription = JSON.stringify(orderData?.prescription);
      if (orData?.type === 1) {
        orData.total = cartData?.subTotal;
        orData.sub_total = cartData?.subTotal;
        orData.items = JSON.stringify(cartData?.cartItems);
      } else {
        orData.total = 0.0;
        orData.sub_total = 0.0;
        orData.items = JSON.stringify([]);
      }

      placeOrder(orData)
        .then((data) => {
          if (data?.data?.status === 1) {
            customerOrder({ customer_id, vendor_id }).then((data) => {
              navigate("/order-confirm", { replace: true });
            });
          }
          setSubmitting(false);
        })
        .catch((error) => console.log(error.error));
    },
    [
      orderData,
      cartData,
      navigate,
      placeOrder,
      customer_id,
      vendor_id,
      customerOrder,
    ]
  );

  return (
    <>
      <TopAndSideBar backPath={"/prescription"} />
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area space-pt--5 space-pb--25">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title">Checkout</h1>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  cart product area ====================*/}
      <Formik
        initialValues={{
          pickup_date: format(startDate, "yyyy-MM-dd", {
            awareOfUnicodeTokens: true,
          }),
          order_note: "",
          address_id: address?.id,
        }}
        validationSchema={orderSchema}
        onSubmit={(values, { setSubmitting }) => {
          onPlaceOrder(values, setSubmitting);
        }}
      >
        {function ({
          handleSubmit,
          isValid,
          errors,
          touched,
          handleChange,
          values,
          isSubmitting,
          setSubmitting,
          setFieldValue,
        }) {
          return (
            <form onSubmit={handleSubmit}>
              <div className="auth-page-body-1">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      {/* Auth form */}
                      <div className="auth-form py-3">
                        <div className="row mb-3">
                          <label
                            htmlFor="pickup_date"
                            className="mb-1 col-4 col-md-1 col-lg-1 col-form-label"
                          >
                            Pickup Date
                          </label>
                          <div className="col-8 col-md-4 col-lg-4">
                            <DatePicker
                              selected={startDate}
                              onChange={function (date) {
                                return onValueSet(date, setFieldValue);
                              }}
                              minDate={new Date()}
                              dateFormat="yyyy-MM-dd"
                              customInput={
                                <input
                                  type="text"
                                  name="pickup_date"
                                  value={values.pickup_date}
                                  placeholder="Pickup Date"
                                  id="pickup_date"
                                  autoComplete="true"
                                  className="form-control"
                                />
                              }
                            />
                            {errors.pickup_date && touched.pickup_date && (
                              <small
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {errors.pickup_date}
                              </small>
                            )}
                          </div>
                        </div>

                        <hr />

                        <div className="row mb-3">
                          <div className="col-12">
                            <label htmlFor="address_id" className="form-label">
                              Address
                            </label>

                            <Link
                              className="text-info float-end"
                              to={"/address"}
                            >
                              Add/Edit Address
                            </Link>

                            <input
                              type="hidden"
                              name="address_id"
                              onChange={handleChange}
                              value={values.address_id}
                              placeholder="Pickup Date"
                              id="address_id"
                              autoComplete="true"
                              className="form-control"
                            />
                            <div className="border rounded p-2 text-muted">
                              <div className="row">
                                <div
                                  className="col-6"
                                  dangerouslySetInnerHTML={{
                                    __html: address?.type
                                      ? "<b>" + address?.type + "</b>"
                                      : "",
                                  }}
                                ></div>
                                <div className="col-6 text-success text-end">
                                  SELECTED
                                </div>
                              </div>
                              <p>
                                {address?.customer_name
                                  ? address?.customer_name + ", "
                                  : ""}
                                {address?.phone ? address?.phone + ", " : ""}
                                {address?.address
                                  ? address?.address + ", "
                                  : ""}
                                {address?.landmark
                                  ? address?.landmark + ", "
                                  : ""}
                                {address?.city ? address?.city + ", " : ""}
                                {address?.state ? address?.state + ", " : ""}
                                {address?.pincode ? address?.pincode : ""}
                              </p>
                            </div>

                            {errors.address_id && touched.address_id && (
                              <small
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {errors.address_id}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label htmlFor="order_note" className="form-label">
                              Order Note
                            </label>
                            <textarea
                              name="order_note"
                              onChange={handleChange}
                              placeholder="Order Note"
                              id="order_note"
                              value={values.order_note}
                              className="form-control"
                            ></textarea>
                            {errors.order_note && touched.order_note && (
                              <small
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {errors.order_note}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grand-total space-mt--20">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <button
                        className="auth-form__button checkout-button"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {isSubmitting ? (
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Place Order"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      <Footer />
    </>
  );
}
