import React from "react";
import TopAndSideBar from "../components/TopAndSideBar";
import Footer from "../components/Footer";

export default function OrderConfirmation() {
  return (
    <>
      <TopAndSideBar />
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area space-pt--5 space-pb--25">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title">Order Confirmation</h1>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  cart product area ====================*/}

      <div className="auth-page-body-1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Auth form */}
              <div className="auth-form py-10">

               <div className="row">

                  <div className="col h-100 text-center align-content-center">
                     <i className="fa fa-check-circle text-success mb-3 h1"></i>
                     <h3 className="text-sucess mb-3">Success</h3>
                     <p>Your order placed successfully. check order history for details.</p>
                  </div>

               </div>

              </div>
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </>
  );
}
