import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toastConfig } from "../../Constants/Config";

export interface ProfileInterface {
  id: number;
  customer_name: string;
  email: string;
  phone: string;
  profile_picture: string;
}

export interface addressInterface {
  id: number;
  address: string;
  landmark: string;
  state: string;
  city: string;
  pincode: string;
}

export interface AuthState {
  isAuth: boolean;
  profile: ProfileInterface;
}

const initialState: AuthState = {
  isAuth: false,
  profile: null,
  addresses: [],
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    callIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    callProfile: (state, action: PayloadAction<ProfileInterface>) => {
      state.profile = action.payload;
    },
    callAddress: (state, action: PayloadAction<Array<addressInterface>>) => {
      state.addresses = action.payload;
    },
    callRemoveAddress: (state, action: PayloadAction<number>) => {
      const index = state.addresses.findIndex(
        (item) => item.id === action.payload
      );

      if (index >= 0) {
        state.addresses.splice(index, 1);
        toast.success("Address removed", toastConfig);
      }
    },
    callLogOut: (state) => {
      state.isAuth = false;
      state.profile = null;
      state.addresses = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  callIsAuth,
  callProfile,
  callAddress,
  callRemoveAddress,
  callLogOut,
} = AuthSlice.actions;

export default AuthSlice.reducer;
