import React from "react";

export default function Loading(props) {
  const { block } = props;
  return (
    <div className="preloader-activate preloader-active">
      <div className="preloader-area-wrap" style={{ opacity: block ? 0.6 : 1 }}>
        <div className="spinner d-flex justify-content-center align-items-center h-100">
          <div className="img-loader" />
        </div>
      </div>
    </div>
  );
}
