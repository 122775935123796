import React, { useCallback, useEffect, useRef, useState } from "react";
import TopAndSideBar from "../components/TopAndSideBar";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store/Store";
import { IMAGE_URL } from "../Constants/Config";
import { useGetAddressMutation } from "../Store/api/AuthApi";
import { useUploadPrescriptionMutation } from "../Store/api/OrderApi";
import { callUpdateOrder } from "../Store/Slices/OrderSlice";

export default function Prescription() {
  const uploadRef = useRef();
  const [uploadPrescription, { isLoading }] = useUploadPrescriptionMutation();
  const [getAddress] = useGetAddressMutation();
  const [validation, setValidation] = useState({ img: false, note: false });
  const [is_checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const customerId = useSelector((state: RootState) => state.auth.profile.id);
  //const vendorId = useSelector((state: RootState) => state.vendor.vendor.id);
  const { type, prescription, prescription_note } = useSelector(
    (state: RootState) => state.order.orderData
  );
  const [notes, setNotes] = useState(prescription_note);

  useEffect(() => {
    getAddress(customerId);
  }, [customerId, getAddress]);

  const onUploadClick = useCallback(() => {
    uploadRef.current.click();
  }, [uploadRef]);

  const onFileSelect = useCallback(
    (param) => {
      const formData = new FormData();
      formData.append(
        "image",
        param.target.files[0],
        param.target.files[0].name
      );
      formData.append("customer_id", customerId);
      formData.append("vendor_id", 0);
      formData.append("address_id", 1);
      uploadPrescription(formData).then((data) => {
        if (data.data?.status === 1) {
          setValidation({ ...validation, img: false });
        }
      });
    },
    [uploadPrescription, customerId, setValidation, validation]
  );

  const onSave = useCallback(
    (path, options = {}) => {
      if (type === 2 && prescription.length === 0) {
        setValidation({ ...validation, img: true });
        return false;
      }
      if (type === 2 && notes === "") {
        setValidation({ ...validation, note: true });
        return false;
      }
      if (type === 1 && !is_checked && prescription.length === 0) {
        setValidation({ ...validation, img: true });
        return false;
      }
      dispatch(callUpdateOrder({ prescription_note: notes }));
      navigate(path, options);
    },
    [
      navigate,
      type,
      setValidation,
      prescription,
      notes,
      validation,
      is_checked,
      dispatch,
    ]
  );

  const onTextChnage = useCallback(
    (value) => {
      setNotes(value);
    },
    [setNotes]
  );

  const onCheck = useCallback(
    (target) => {
      if (target.checked) {
        setChecked(true);
        setValidation({ img: false, note: false });
      } else {
        setChecked(false);
        setValidation({ ...validation, img: true });
      }
    },
    [setValidation, validation]
  );

  return (
    <>
      <TopAndSideBar backPath={type === 1 ? "/cart" : ""} />
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area space-pt--5 space-pb--25">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title">Upload Prescription</h1>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  cart product area ====================*/}
      <div className="cart-product-area container">
        <div className="card">
          <div className="card-body">
            <div className="row" onClick={onUploadClick}>
              <div className="col-2 col-md-1">
                <img
                  src="assets/img/icons/prescr-.svg"
                  className="float-start"
                  alt="prescription"
                  height={36}
                />
              </div>
              <div className="col-10 col-md-11">
                <h6>Add New Prescription</h6>
                <p>Upload a Doctor's Prescription</p>
              </div>
              <input
                type="file"
                onChange={onFileSelect}
                ref={uploadRef}
                accept="image/png, image/jpg, image/jpeg"
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
        {validation?.img && (
          <small className="invalid-feedback" style={{ display: "block" }}>
            Please upload a prescription.
          </small>
        )}
        <div className="row mt-2 container">
          {prescription &&
            prescription.map((img, i) => (
              <div className="col-4 col-md-1 ps-0 mb-1" key={i}>
                <img
                  src={IMAGE_URL + img}
                  className="img-thumbnail rounded img-fluid"
                  alt=""
                />
              </div>
            ))}

          {isLoading && (
            <div className="col-4 col-md-1 bg-white img-thumbnail rounded text-center align-content-center">
              <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>

        {type === 2 && (
          <div className="row">
            <div className="col-12">
              <label htmlFor="prescription_note" className="form-label">
                Prescription Note
              </label>
              <textarea
                name="prescription_note"
                onChange={(e) => onTextChnage(e.target.value)}
                placeholder="Prescription Note"
                id="prescription_note"
                value={notes}
                className="form-control"
                maxLength={250}
              ></textarea>
              {validation?.note && (
                <small
                  className="invalid-feedback"
                  style={{ display: "block" }}
                >
                  Please add prescription notes.
                </small>
              )}
            </div>
          </div>
        )}

        {type === 1 && (
          <div className="form-check mt-2">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              onChange={(e) => onCheck(e.target)}
              //checked={address?.selected ? "checked" : ""}
            />
            <label className="form-check-label" htmlFor="flexCheckChecked">
              Unable to upload now.
            </label>
          </div>
        )}
      </div>

      <div className="grand-total space-mt--20">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="checkout-button text-center"
                onClick={() =>
                  onSave("/checkout", {
                    replace: true,
                    state: {
                      back: "cart",
                      from: "prescription",
                      to: "checkout",
                    },
                  })
                }
              >
                Continue
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
