import React from "react";
import { useLocation } from "react-router-dom";
import { IMAGE_URL } from "../Constants/Config";
import TopAndSideBar from "../components/TopAndSideBar";
import Footer from "../components/Footer";
import { useDispatch } from "react-redux";
import { callUpdateCart } from "../Store/Slices/CartSlice";

export default function ProductDetails() {
  const location = useLocation();
  const product = location.state?.item;
  const dispatch = useDispatch();

  const addTocart = async (
    qty,
    item_id,
    item_name,
    price,
    item_image,
    unit,
    marked_price
  ) => {
    let item = {
      product_id: item_id,
      product_name: item_name,
      qty: qty,
      unit: unit,
      marked_price: marked_price,
      price_per_item: parseFloat(price),
      price: parseFloat(qty * price),
      image: item_image,
    };
    dispatch(callUpdateCart(item));
  };

  return (
    <>
      <TopAndSideBar />
      <div className="product-image-slider-wrapper pb-3 mb-3">
        <div className="product-image-single text-center">
          <img
            src={IMAGE_URL + product?.image}
            className="img-fluid"
            alt={product?.product_name}
          />
        </div>
      </div>

      <div className="product-content-header-area border-bottom--thick pb-3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="product-content-header">
                <div className="product-content-header__main-info">
                  <h3 className="title">{product?.product_name}</h3>
                  <div className="notification-item__time p-0 mb-1">{product?.unit}</div>
                  <div className="notification-item__time p-0 mb-2">{product.company}</div>
                  <div className="notification-item__time p-0"><b>Seller</b> : {product.store_name}</div>
                  <div className="price">
                    <span className="main-price">
                      {"₹" + product?.marked_price}
                    </span>{" "}
                    <span className="discounted-price">{"₹" + product?.price}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-content-description border-bottom--thick pt-2 pb-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="mb-2">{product?.description}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="shop-product-button">
        <button
          className="cart"
          onClick={() =>
            addTocart(
              1,
              product.id,
              product.product_name,
              product.price,
              product.image,
              product.unit,
              product.marked_price
            )
          }
        >
          ADD TO CART
        </button>
        <button
          className="buy"
          onClick={() =>
            addTocart(
              1,
              product.id,
              product.product_name,
              product.price,
              product.image,
              product.unit,
              product.marked_price
            )
          }
        >
          BUY NOW
        </button>
      </div>
      <Footer />
    </>
  );
}
