import React from "react";
import TopAndSideBar from "../components/TopAndSideBar";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../Store/Store";
import { IMAGE_URL } from "../Constants/Config";
import { Link } from "react-router-dom";

export default function Profile() {
  const customer = useSelector((state: RootState) => state.auth.profile);

  return (
    <>
      <TopAndSideBar />
      <div className="breadcrumb-area space-pt--5 space-pb--25">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h1 className="page-title">User Profile</h1>
            </div>
            <div className="col-6 text-end">
              <Link to={"/update-profile"} className="btn btn-outline-danger">
                Edit Profile
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/*====================  profile header ====================*/}
      <div className="profile-header-area space-pt--30 space-mb--40">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="profile-header">
                <div className="profile-header__image">
                  <img
                    src={IMAGE_URL + customer.profile_picture}
                    className="img-fluid"
                    alt={customer.customer_name}
                  />
                </div>
                <div className="profile-header__content space-mt--10">
                  <h3 className="name space-mb--15">
                    {customer.customer_name.toUpperCase()}
                  </h3>
                  <div className="profile-info space-mb--10">
                    <div className="profile-info-block">
                      <div className="profile-info-block__value">
                        {customer.id}
                      </div>
                      <div className="profile-info-block__title">
                        Customer ID
                      </div>
                    </div>
                    <div className="profile-info-block">
                      <div className="profile-info-block__value">
                        {customer.created_at ?? customer?.c_date}
                      </div>
                      <div className="profile-info-block__title">
                        Active Since
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of profile header  ====================*/}
      {/*====================  profile body ====================*/}
      <div className="profile-body-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="profile-body">
                <div className="profile-info-table space-mb--40">
                  <div className="profile-info-block">
                    <div className="profile-info-block__title">Full Name</div>
                    <div className="profile-info-block__value">
                      {customer.customer_name}
                    </div>
                  </div>
                  {/* <div className="profile-info-block">
                 <div className="profile-info-block__title">User Name</div>
                 <div className="profile-info-block__value">john007</div>
               </div> */}
                  <div className="profile-info-block">
                    <div className="profile-info-block__title">Phone</div>
                    <div className="profile-info-block__value">
                      {customer.phone_number}
                    </div>
                  </div>
                  <div className="profile-info-block">
                    <div className="profile-info-block__title">
                      E-mail Address
                    </div>
                    <div className="profile-info-block__value">
                      {customer.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of profile body  ====================*/}
      <Footer />
    </>
  );
}
