import { combineSlices } from "@reduxjs/toolkit";
import { AuthSlice } from "./Slices/AuthSlice";
import { UtilitySlice } from "./Slices/UtilitySlice";
import { OrderSlice } from "./Slices/OrderSlice";
//import { ProductSlice } from "./Slices/ProductSlice";
//import { CartSlice } from "./Slices/CartSlice";
import { vendorApi } from "./api/VendorApi";
import { productApi } from "./api/ProductApi";
import { VendorSlice } from "./Slices/VendorSlice";
import { CartSlice } from "./Slices/CartSlice";
import { authApi } from "./api/AuthApi";
import { orderApi } from "./api/OrderApi";

export const rootReducer = combineSlices(
  AuthSlice,
  UtilitySlice,
  OrderSlice,
  //CartSlice,
  CartSlice,
  VendorSlice,
  { [vendorApi.reducerPath]: vendorApi.reducer },
  { [productApi.reducerPath]: productApi.reducer },
  { [authApi.reducerPath]: authApi.reducer },
  { [orderApi.reducerPath]: orderApi.reducer },
);
