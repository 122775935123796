import { configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootReducer } from ".";
import { vendorApi } from "./api/VendorApi";
import { productApi } from "./api/ProductApi";
import { authApi } from "./api/AuthApi";
import { orderApi } from "./api/OrderApi";

const persistConfig = {
  key: "root",
  version: 1,
  blacklist: [
    "utility",
    "vendorApi",
    "productApi",
    "authApi",
    "order",
    "orderApi",
  ],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  //reducer: rootReducer,
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      vendorApi.middleware,
      productApi.middleware,
      authApi.middleware,
      orderApi.middleware,
    ]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
