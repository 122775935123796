import React, { useCallback } from "react";
import Footer from "../components/Footer";
import TopAndSideBar from "../components/TopAndSideBar";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../Store/Store";
import { useNavigate } from "react-router-dom";
import {
  useCustomerImageMutation,
  useUpdateCustomerProfileMutation,
} from "../Store/api/AuthApi";

const updateProfileSchema = Yup.object().shape({
  customer_name: Yup.string().required("Enter cutomer name."),
  phone_number: Yup.number()
    .min(10, "Please enter correct mobile number.")
    .required("Mobile number is required."),
  email: Yup.string()
    .email("Please enter valid email.")
    .required("Email address is required."),
});
export default function UpdateProfile() {
  const navigate = useNavigate();
  const profile = useSelector((state: RootState) => state.auth.profile);
  const [updateProfile] = useUpdateCustomerProfileMutation();
  const [updateImage] = useCustomerImageMutation();

  const onClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleUpload = useCallback(
    (e, formikSetVlaue) => {
      updateImage(e.target.files[0])
        .then((res) => {
          formikSetVlaue("profile_picture", res?.data?.result);
        })
        .catch((err) => console.log(err));
    },
    [updateImage]
  );

  return (
    <>
      <TopAndSideBar />
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area space-pt--5 space-pb--25">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title">Update Profile</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="auth-page-body-1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Auth form */}
              <div className="auth-form p-3">
                <Formik
                  initialValues={{
                    id: profile?.id,
                    customer_name: profile?.customer_name,
                    phone_number: profile?.phone_number,
                    email: profile?.email,
                    profile_picture: profile?.profile_picture,
                  }}
                  validationSchema={updateProfileSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    updateProfile(values).then((data) => {
                      setSubmitting(false);
                      onClose();
                    });
                  }}
                >
                  {function ({
                    handleSubmit,
                    isValid,
                    errors,
                    touched,
                    handleChange,
                    values,
                    isSubmitting,
                    setSubmitting,
                    setFieldValue,
                  }) {
                    return (
                      <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="col-md-6">
                          <label htmlFor="email" className="form-label">
                            Profile picture
                          </label>
                          <input
                            className="form-control"
                            type="file"
                            onChange={(e) => handleUpload(e, setFieldValue)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="customer_name" className="form-label">
                            Customer Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="customer_name"
                            name="customer_name"
                            onChange={handleChange}
                            value={values.customer_name}
                            placeholder="Customer Name"
                            autoComplete="true"
                          />
                          {errors.customer_name && touched.customer_name && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.customer_name}
                            </small>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="phone" className="form-label">
                            Phone Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone_number"
                            name="phone_number"
                            onChange={handleChange}
                            value={values.phone_number}
                            placeholder="Phone Number"
                            autoComplete="true"
                          />
                          {errors.phone && touched.phone && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.phone}
                            </small>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            placeholder="Email"
                            autoComplete="true"
                          />
                          {errors.email && touched.email && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.email}
                            </small>
                          )}
                        </div>

                        <div className="col-12">
                          <button
                            className="auth-form__button checkout-button"
                            disabled={isSubmitting}
                            type="submit"
                          >
                            {isSubmitting ? (
                              <div
                                className="spinner-border text-light"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
