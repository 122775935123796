import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

export interface slider {
  title: string;
  sub_title: string;
  image: string;
}

export interface company {
  company: string;
  image: string;
}

export interface vendor {
  id: number;
  slug: string;
  store_name: string;
  address: string;
  email: strig;
  phone_number: string;
  pin_code: string;
  profile_picture: string;
}

export interface VendorState {
  vendors: Array<vendor>;
  vendor: vendor;
  sliders: Array<slider>;
  companies: Array<company>;
}

const initialState: VendorState = {
  vendors: [],
  vendor: null,
  sliders: [],
  companies: [],
};

export const VendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    callAddVendor: (state, action: PayloadAction<vendor>) => {
      const index = state.vendors.findIndex(
        (item) => item.id === action.payload.id
      );

      if (index > -1) {
        state.vendors[index] = action.payload;
      } else {
        state.vendors.push(action.payload);
      }

      state.vendor = action.payload;
    },
    callSetVendor: (state, action: PayloadAction<vendor>) => {
      state.vendor = action.payload;
    },
    callSetData: (
      state,
      action: PayloadAction<{
        sliders: Array<slider>,
        companies: Array<company>,
      }>
    ) => {
      state.sliders = action.payload.sliders;
      state.companies = action.payload.companies;
    },
  },
});

// Action creators are generated for each case reducer function
export const { callAddVendor, callSetVendor, callSetData } =
  VendorSlice.actions;

export default VendorSlice.reducer;
