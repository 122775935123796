import React, { useEffect, useState, useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import TopAndSideBar from "../components/TopAndSideBar";
import { RootState } from "../Store/Store";
import { useDispatch, useSelector } from "react-redux";
import { useGetVendorProductsMutation } from "../Store/api/ProductApi";
import { Container } from "reactstrap";

import ProductList from "../components/ProductList";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { callIsSearch } from "../Store/Slices/UtilitySlice";
import { callUpdateOrder } from "../Store/Slices/OrderSlice";
import SliderItem from "../components/SliderItem";
import BrandComponent from "../components/BrandComponent";
//import { useGetVendorBySlugMutation } from "../Store/api/VendorApi";

function Home() {
  const [searchString, setSearchString] = useState("");
  const [getProducts, { data, reset }] = useGetVendorProductsMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sliders = useSelector((state: RootState) => state.vendor.sliders);
  const companies = useSelector((state: RootState) => state.vendor.companies);

  const topSlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: sliders?.length > 1 ? true : false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: sliders?.length > 1 ? true : false,
  };

  const brandSlider = {
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const isSearchFocus = useSelector(
    (state: RootState) => state.utility.isSearch
  );

  //   const vendor = useSelector((state: RootState) => state.vendor.vendor);

  //   const [findVendor] = useGetVendorBySlugMutation();

  //   useEffect(
  //     () => {
  //       if (vendor?.id) {
  //         findVendor(vendor?.slug);
  //       }
  //     },
  //     [findVendor, vendor?.id, vendor?.slug]
  //   );

  const onSearchInput = (value) => {
    setSearchString(value);
    const searchQuery = document.getElementById("header-search-input").value;

    if (searchQuery.length > 2) {
      //const data = { vendor_id: vendor?.id, q: value };
      const data = { q: value };
      getProducts(data);
    } else {
      reset();
    }
  };

  useEffect(() => {
    if (searchString === "") {
      reset();
    }
  }, [searchString, reset]);

  const renderProduct = useCallback(() => {
    if (data?.result?.length > 0) {
      return data?.result.map((product, i) => {
        return <ProductList product={product} key={i} />;
      });
    } else {
      return <h5 className="text-center">No Product found</h5>;
    }
  }, [data]);

  const onMedicineSearchClick = useCallback(() => {
    dispatch(callIsSearch(true));
  }, [dispatch]);

  const onNavigate = useCallback(
    (path, options = {}) => {
      dispatch(callUpdateOrder({ type: 2 }));
      navigate(path, options);
    },
    [dispatch, navigate]
  );

  return (
    <>
      <TopAndSideBar
        onSearchInput={onSearchInput}
        ipValue={searchString}
        search={true}
      />

      {isSearchFocus && (
        <Container className="list-container">{renderProduct()}</Container>
      )}

      {!isSearchFocus && (
        <>
          <div className="hero-slider space-mb--25">
            <div className="container">
              <div className="row row-10">
                <div className="col-12">
                  {sliders?.length > 0 && (
                    <Slider className="hero-slider-wrapper" {...topSlider}>
                      {sliders.map((slide, i) => {
                        return <SliderItem slide={slide} key={i} />;
                      })}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="new-product-area bg-color--white space-xy--10 space-mb--20">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {/* section title */}
                  <h2 className="section-title space-mb--20">
                    Choose the help you need
                  </h2>
                  {/* new products */}
                  <div className="new-product-wrapper space-mb-m--15 pt-3 pb-3">
                    <div className="row row-10">
                      <div className="col-6 col-lg-2 col-md-3 text-center">
                        <div className="grid-product space-mb--20 shadow p-2">
                          <div className="grid-product__image">
                            <span onClick={onMedicineSearchClick}>
                              <img
                                src="assets/img/products/medicine.svg"
                                className="img-fluid quicklink-img"
                                alt=""
                              />
                            </span>
                          </div>
                          <div className="grid-product__content">
                            <h3 className="title">
                              <span onClick={onMedicineSearchClick}>
                                Medicine Enquiry
                              </span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-lg-2 col-md-3 text-center">
                        <div className="grid-product space-mb--20 shadow p-2">
                          <div
                            className="grid-product__image"
                            onClick={() => onNavigate("/prescription")}
                          >
                            <img
                              src="assets/img/products/prescription.svg"
                              className="img-fluid quicklink-img"
                              alt=""
                            />
                          </div>
                          <div className="grid-product__content">
                            <h3 className="title">
                              {/* <Link to="/prescription" state={{ cart: false }}> */}
                              <span onClick={() => onNavigate("/prescription")}>
                                Upload Prescription
                              </span>
                              {/* </Link> */}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-lg-2 col-md-3 text-center">
                        <div className="grid-product space-mb--20 shadow p-2">
                          <div className="grid-product__image">
                            <Link to={"/orders"}>
                              <img
                                src="assets/img/products/order-history.svg"
                                className="img-fluid quicklink-img"
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="grid-product__content">
                            <h3 className="title">
                              <Link to={"/orders"}>Enquiry History</Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-lg-2 col-md-3 text-center">
                        <div className="grid-product space-mb--20 shadow p-2">
                          <div className="grid-product__image">
                            <Link to={"/contact"}>
                              <img
                                src="assets/img/products/contact.svg"
                                className="img-fluid quicklink-img"
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="grid-product__content">
                            <h3 className="title">
                              <Link to={"/contact"}>Contact Store</Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-lg-2 col-md-3 text-center">
                        <div className="grid-product space-mb--20 shadow p-2">
                          <div className="grid-product__image">
                            <Link to={"/profile"}>
                              <img
                                src="assets/img/products/profile.svg"
                                className="img-fluid quicklink-img"
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="grid-product__content">
                            <h3 className="title">
                              <Link to={"/profile"}>Profile</Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-6">
                    <div className="grid-product space-mb--20">
                      <div className="grid-product__image">
                        <a href="shop-product.html">
                          <img
                            src="assets/img/products/product2.png"
                            className="img-fluid"
                            alt=""
                          />
                        </a>
                        <button className="icon">
                          <img
                            src="assets/img/icons/heart-orange.svg"
                            className="injectable"
                            alt=""
                          />
                        </button>
                      </div>
                      <div className="grid-product__content">
                        <h3 className="title">
                          <a href="shop-product.html">Men Shirt</a>
                        </h3>
                        <div className="price">
                          <span className="discounted-price">$30</span>
                          <span className="main-price">$40</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="category-slider-area bg-color--white space-xy--10 space-mb--25">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {/* section title */}
                  <h2 className="section-title space-mb--30">Our Top Brands</h2>
                  {/* category slider */}
                  {companies?.length > 0 && (
                    <Slider
                      className="category-slider-wrapper"
                      {...brandSlider}
                    >
                      {companies.map((slide, i) => {
                        if (slide?.image) {
                          return <BrandComponent brand={slide} key={i} />;
                        }
                        return null;
                      })}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
}

export default Home;
