import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./Pages/Home";
import Cart from "./Pages/Cart";
import Profile from "./Pages/Profile";
import { useSelector } from "react-redux";
import { RootState } from "./Store/Store";
//import Footer from "./components/Footer";
import Loading from "./components/Loading";
import PrivateRoute from "./components/PrivateRoute";
import OrderHistory from "./Pages/OrderHistory";
import Prescription from "./Pages/Prescription";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Contact from "./Pages/Contact";
import Checkout from "./Pages/Checkout";
import "react-toastify/dist/ReactToastify.css";
import Address from "./Pages/Address";
import AddAddress from "./Pages/AddAddress";
import UpdateAddress from "./Pages/UpdateAddress";
import OrderConfirmation from "./Pages/OrderConfirmation";
import { useCustomerOrdersMutation } from "./Store/api/OrderApi";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import ProductDetails from "./Pages/ProductDetails";
import { useGetDataMutation } from "./Store/api/VendorApi";
import UpdateProfile from "./Pages/UpdateProfile";

function App() {
  //const vendor_id = useSelector((state: RootState) => state.vendor.vendor?.id);
  const isAuth = useSelector((state: RootState) => state.auth.isAuth);
  const customer_id = useSelector((state: RootState) => state.auth.profile?.id);
  const [isLoading, setLoading] = useState(true);
  const [customerOrder] = useCustomerOrdersMutation();
  const [getData] = useGetDataMutation();
  const vendor_id = 0;

  useEffect(() => {
    getData().then(() => {
      setLoading(false);
    });
  }, [getData, setLoading]);

  useEffect(() => {
    if (isAuth && customer_id) {
      customerOrder({ customer_id, vendor_id });
    }
  }, [isAuth, customer_id, customerOrder]);

  return (
    <BrowserRouter>
      {isLoading && <Loading />}
      {/*====================  End of preloader area  ====================*/}

      <div className="space-pb--50">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          {/* <Route path="/s/:slug" element={<Store />} /> */}
          <Route path="/detail" element={<ProductDetails />} />
          <Route element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/orders" element={<OrderHistory />} />
            <Route path="/prescription" element={<Prescription />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/address" element={<Address />} />
            <Route path="/add-address" element={<AddAddress />} />
            <Route path="/update-address" element={<UpdateAddress />} />
            <Route path="/order-confirm" element={<OrderConfirmation />} />
            <Route path="/update-profile" element={<UpdateProfile />} />
          </Route>

          {/* <Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
        {/*====================  footer area ====================*/}
        {/* <Footer /> */}
        {/*====================  End of footer area  ====================*/}
      </div>
      <ToastContainer limit={1} />
    </BrowserRouter>
  );
}

export default App;
