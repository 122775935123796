import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { callIsSearch } from "../Store/Slices/UtilitySlice";
import { RootState } from "../Store/Store";
import { callClearOrder } from "../Store/Slices/OrderSlice";
import { IMAGE_URL } from "../Constants/Config";
import { callLogOut } from "../Store/Slices/AuthSlice";

export default function TopAndSideBar(props) {
  const { onSearchInput, ipValue, search, backPath } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [isVisible, setMenuVisible] = useState(false);
  const [canBack, setCanBack] = useState(false);
  const navigate = useNavigate();
  const customer = useSelector((state: RootState) => state.auth.profile);

  const isSearchFocus = useSelector(
    (state: RootState) => state.utility.isSearch
  );

  const onMenuClick = useCallback(() => {
    if (!isVisible) document.body.style.overflow = "hidden";
    setMenuVisible((isVisible) => !isVisible);
  }, [isVisible]);

  const onOutsideClick = useCallback(() => {
    if (isVisible) {
      setMenuVisible(false);
      document.body.style.overflow = "auto";
    }
  }, [isVisible]);

  const onSearchFocus = useCallback(() => {
    dispatch(callIsSearch(true));
  }, [dispatch]);

  const onBackClick = useCallback(() => {
    dispatch(callIsSearch(false));
    onSearchInput("");
  }, [dispatch, onSearchInput]);

  const navigateBack = useCallback(() => {
    if (!isSearchFocus) {
      if (backPath) {
        navigate(backPath, {
          replace: true,
        });
      } else {
        navigate(-1);
      }
    }
  }, [navigate, isSearchFocus, backPath]);

  useEffect(() => {
    dispatch(callIsSearch(false));
    if (location?.pathname !== "/" && location?.key !== "default") {
      setCanBack(true);
    } else {
      setCanBack(false);
    }

    if (location?.pathname === "/cart" || location?.pathname === "/") {
      dispatch(callClearOrder());
    }
  }, [dispatch, location, setCanBack]);

  useEffect(() => {
    const onScroll = (event) => {
      var scroll = window.scrollY;
      if (scroll < 100) {
        document.getElementsByClassName("sticker")[0].classList.remove("stick");
        document
          .getElementsByTagName("body")[0]
          .classList.remove("space-pt--120");
      } else {
        document.getElementsByClassName("sticker")[0].classList.add("stick");
        document.getElementsByTagName("body")[0].classList.add("space-pt--120");
      }
    };

    window.addEventListener("scroll", onScroll);
    document.body.style.overflow = "auto";

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const signOut = useCallback(() => {
    dispatch(callLogOut());
    onOutsideClick();
  }, [dispatch, onOutsideClick]);

  const renderMenu = useCallback(() => {
    if (canBack || isSearchFocus) {
      return (
        <button
          className="header-menu-trigger d-flex align-items-center"
          onClick={canBack ? navigateBack : onBackClick}
        >
          <i className="fa fa-chevron-left text-danger fs-3"></i>
        </button>
      );
    } else {
      return (
        <button
          className="header-menu-trigger d-flex align-items-center"
          onClick={onMenuClick}
        >
          <i className="fa fa-bars text-danger fs-3"></i>
        </button>
      );
    }
  }, [isSearchFocus, canBack, onMenuClick, navigateBack, onBackClick]);

  return (
    <>
      <div
        className={`body-wrapper ${
          isVisible ? "overflow-hidden active-overlay" : ""
        }`}
        onClick={onOutsideClick}
      ></div>
      {/*====================  header area ====================*/}
      <header className="sticker">
        <div className="header-wrapper">
          <div className="container-fluid space-y--15 bg-white shadow-sm mb-2">
            <div className="row align-items-center">
              <div className="col-6">
                {/* header logo */}
                <div className="header-logo d-flex">
                  {renderMenu()}
                  <Link className="ms-2" to={"/"}>
                    <img
                      src="assets/img/logo-2.png"
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {search && (
            <div className="container">
              <div className="row">
                <div className="col">
                  {/* header search */}
                  <div className="header-search space-pb--15">
                    <form>
                      <input
                        type="text"
                        id="header-search-input"
                        placeholder="Search anything"
                        onChange={(e) => onSearchInput(e.target.value)}
                        onFocus={onSearchFocus}
                        value={ipValue}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                      />
                      <img
                        src="assets/img/icons/search-2.svg"
                        className="injectable"
                        alt=""
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </header>
      {/*====================  End of header area  ====================*/}
      {/*====================  mobile menu overlay ====================*/}
      <div
        className={`offcanvas-menu ${isVisible ? "active" : ""}`}
        id="offcanvas-menu"
      >
        {customer && (
          <div className="profile-card text-center">
            <div className="profile-card__image space-mb--10">
              <img
                src={IMAGE_URL + customer.profile_picture}
                className="img-fluid"
                alt={customer.customer_name}
                width={64}
              />
            </div>
            <div className="profile-card__content">
              <p className="name">
                {customer.customer_name.toUpperCase()}{" "}
                <span className="id">ID {customer.id}</span>
              </p>
            </div>
          </div>
        )}

        <div className="offcanvas-navigation-wrapper space-mt--40">
          <ul className="offcanvas-navigation">
            {customer ? (
              <>
                <li>
                  <span className="icon">
                    <img
                      src="assets/img/icons/profile-two.svg"
                      className="injectable"
                      alt=""
                    />
                  </span>
                  <Link to={"/profile"}>Profile</Link>
                </li>
                <li onClick={signOut}>
                  <span className="icon">
                    <i className="fa fa-sign-out"></i>
                  </span>
                  <span>Logout</span>
                </li>
              </>
            ) : (
              <li>
                <span className="icon">
                  <i className="fa fa-sign-in"></i>
                </span>
                <Link to={"/login"}>Sign In</Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
