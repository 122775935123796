import React, { useCallback } from "react";
import TopAndSideBar from "../components/TopAndSideBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store/Store";
import CartProduct from "../components/CartProduct";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { callUpdateOrder } from "../Store/Slices/OrderSlice";
//import { callIsSearch } from "../Store/Slices/UtilitySlice";

export default function Cart() {
  const { cartItems, subTotal } = useSelector((state: RootState) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onNavigate = useCallback(
    (path, options = {}) => {
      dispatch(callUpdateOrder({ type: 1 }));
      navigate(path, options);
    },
    [navigate, dispatch]
  );

  const goToHome = useCallback(
    (path, options = {}) => {
      //dispatch(callIsSearch(true));
      navigate(path, options);
    },
    [navigate]
  );

  return (
    <>
      <TopAndSideBar />
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area space-pt--5 space-pb--25">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title">Cart</h1>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  cart product area ====================*/}
      {cartItems.length > 0 ? (
        <>
          <div className="cart-product-area">
            {cartItems.length > 0 ? (
              cartItems.map((product, i) => {
                return <CartProduct product={product} key={i} />;
              })
            ) : (
              <h5 className="text-center">No Product in cart.</h5>
            )}
          </div>

          <div className="grand-total space-mt--20">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="title-wrap">
                    <h4 className="cart-bottom-title">Cart Total</h4>
                  </div>
                  <h5>
                    Total products <span>{"₹" + subTotal}</span>
                  </h5>
                  <h4 className="grand-total-title">
                    Grand Total <span>{"₹" + subTotal}</span>
                  </h4>
                  <div
                    onClick={() =>
                      onNavigate("/prescription", { replace: true, state: {} })
                    }
                    className="auth-form__button checkout-button text-center"
                  >
                    Continue
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="container text-center">
          <h5 className="text-center">No Product in cart.</h5>
          <button
            className="btn btn-danger mt-3"
            onClick={() => goToHome("/", { replace: true })}
          >
            Search Product
          </button>
        </div>
      )}
      <Footer />
    </>
  );
}
