import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  API_URL,
  GET_DATA_URL,
  VENDOR_PROFILE_URL,
} from "../../Constants/Config";
import { callIsError, callMessage } from "../Slices/UtilitySlice";
import { callAddVendor, callSetData } from "../Slices/VendorSlice";

export const vendorApi = createApi({
  reducerPath: "vendorApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getVendorBySlug: builder.mutation({
      query: (slug) => ({
        url: VENDOR_PROFILE_URL,
        method: "POST",
        body: { vendor_slug: slug },
      }),
      onQueryStarted: async (arg, api) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          dispatch(callIsError(true));
          dispatch(callMessage(data.message));
          window.history.pushState({}, "Home", "/");
        } else {
          dispatch(callAddVendor(data.result));
          window.history.pushState({}, "Home", "/");
        }
      },
    }),
    getData: builder.mutation({
      query: (slug) => ({
        url: GET_DATA_URL,
        method: "POST",
      }),
      onQueryStarted: async (arg, api) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          dispatch(callIsError(true));
          dispatch(callMessage(data.message));
        } else {
          dispatch(callSetData(data.result));
        }
      },
    }),
  }),
});

export const { useGetVendorBySlugMutation, useGetDataMutation } = vendorApi;
