import React from "react";
import { IMAGE_URL } from "../Constants/Config";

export default function SliderItem(props) {
  const { slide } = props;
  return (
    <div
      style={{
        backgroundImage: `url('` + IMAGE_URL + slide?.image + `')`,
      }}
      className="hero-slider-item d-flex bg-img"
    >
      <div className="container">
        <div className="row">
          <div className="col-6 ms-auto">
            {/* hero slider content */}
            {/* <div className="myclass"></div> */}
            <div className="hero-slider-content">
              <h1 className="hero-slider-content__title space-mb--10">
                {slide?.title}
              </h1>
              <p className="hero-slider-content__text">{slide?.sub_title}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
