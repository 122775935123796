import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
//import { useSelector } from "react-redux";
//import { RootState } from "../Store/Store";
import {
  useForgotPasswordMutation,
  useVerifyOTPMutation,
} from "../Store/api/AuthApi";
import OtpInputs from "../components/OtpInputs";

const ForgotPasswordSchema = Yup.object().shape({
  phone_number: Yup.number()
    .min(10, "Please enter correct mobile number.")
    .required("Mobile number is required."),
});

export default function ForgotPassword() {
  const numberOfDigits = 4;
  const navigate = useNavigate();
  //const vendor_id = useSelector((state: RootState) => state.vendor.vendor?.id);
  const [mobile, setMobile] = useState(null);
  const [forgotPassword] = useForgotPasswordMutation();
  const [verifyOtp, { isLoading }] = useVerifyOTPMutation();
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [otpError, setOtpError] = useState(false);

  useEffect(() => {
    document.body.classList.add("space-pt--70");
    document.body.classList.remove("space-pt--120");
    return function cleanup() {
      document.body.classList.remove("space-pt--70");
    };
  }, []);

  const onClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const closeVerifyOtpScreen = useCallback(() => {
    setMobile(null);
  }, [setMobile]);

  const onForgotPassword = useCallback(
    (formdata, setSubmitting) => {
      forgotPassword(formdata).then((data) => {
        setSubmitting(false);
        if (data?.data?.status === 1) {
          setMobile(data?.data?.result?.id);
        }
      });
    },
    [setMobile, forgotPassword]
  );

  const validateOtp = useCallback(() => {
    setOtpError(false);
    for (let index = 0; index < otp.length; index++) {
      if (otp[index] === "") {
        setOtpError(true);
        break;
      }
    }
  }, [otp, setOtpError]);

  const onVerifyOtp = useCallback(() => {
    validateOtp();
    if (!otpError) {
      verifyOtp({ id: mobile, otp: otp.join("") }).then((data) => {
        if (data?.data?.status === 1) {
          //setMobile(data?.data?.result?.id);
          navigate("/reset-password", {
            state: { id: data?.data?.result?.id },
          });
        }
      });
    }
  }, [otp, otpError, validateOtp, verifyOtp, navigate, mobile]);

  return (
    <>
      {!mobile && (
        <>
          <div className="auth-page-header space-mb--50">
            <div className="container">
              <div className="row">
                <div className="col-10">
                  <h3 className="auth-page-header__title">Forgot Password?</h3>
                  <p className="auth-page-header__text">
                    Enter your phone number to get OTP on your Email.
                  </p>
                </div>
                <div className="col-2 text-end fs-4">
                  <i className="fa fa-times" onClick={onClose}></i>
                </div>
              </div>
            </div>
          </div>
          <div className="auth-page-body">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="auth-form">
                    <Formik
                      initialValues={{ phone_number: "" }}
                      validationSchema={ForgotPasswordSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        onForgotPassword(values, setSubmitting);
                      }}
                    >
                      {function ({
                        handleSubmit,
                        isValid,
                        errors,
                        touched,
                        handleChange,
                        values,
                        isSubmitting,
                        setSubmitting,
                      }) {
                        return (
                          <form onSubmit={handleSubmit}>
                            <div className="auth-form__single-field space-mb--30">
                              <label htmlFor="phone">Mobile Number</label>
                              <input
                                type="text"
                                name="phone_number"
                                onChange={handleChange}
                                value={values.phone_number}
                                placeholder="Enter Mobile Number"
                                id="phone"
                                autoComplete="true"
                              />
                              {errors.phone_number && touched.phone_number && (
                                <small
                                  className="invalid-feedback"
                                  style={{ display: "block" }}
                                >
                                  {errors.phone_number}
                                </small>
                              )}
                            </div>
                            <button
                              className="auth-form__button"
                              disabled={isSubmitting}
                              type="submit"
                            >
                              {isSubmitting ? (
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                "Send OTP"
                              )}
                            </button>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {mobile && (
        <>
          <div className="auth-page-header space-mb--50">
            <div className="container">
              <div className="row">
                <div className="col-10">
                  <h3 className="auth-page-header__title">Verify OTP</h3>
                  <p className="auth-page-header__text">
                    Enter the OTP you got on your email inbox (check spam if you
                    didn't get on your inbox.).
                  </p>
                </div>
                <div className="col-2 text-end fs-4">
                  <i className="fa fa-times" onClick={closeVerifyOtpScreen}></i>
                </div>
              </div>
            </div>
          </div>
          <div className="auth-page-body">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="auth-form text-center">
                    <OtpInputs
                      numberOfDigits={numberOfDigits}
                      setOtp={setOtp}
                      otp={otp}
                    />
                    {otpError && (
                      <small
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Please enter correct OTP.
                      </small>
                    )}
                    <button
                      className="auth-form__button mt-3"
                      disabled={isLoading}
                      onClick={onVerifyOtp}
                      type="submit"
                    >
                      {isLoading ? (
                        <div
                          className="spinner-border text-light"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Verify OTP"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
