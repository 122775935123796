import React, { useCallback, memo } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  useDeleteAddressMutation,
  useGetAddressMutation,
  useSetAddressMutation,
} from "../Store/api/AuthApi";
import { useNavigate } from "react-router-dom";

export default memo(function AddressItem(props) {
  const { address, index } = props;
  const [setAddress] = useSetAddressMutation();
  const [deleteAddress] = useDeleteAddressMutation();
  const [getAddress] = useGetAddressMutation();

  const navigate = useNavigate();

  const confirmDelete = useCallback(
    (id) => {
      withReactContent(Swal)
        .fire({
          title: "",
          html: "<b>Do you want to delete address?</b>",
          confirmButtonText: "Yes",
          showCancelButton: true,
          cancelButtonText: "No",
        })
        .then((result) => {
          deleteAddress(address);
        });
    },
    [address, deleteAddress]
  );

  const confirmEdit = useCallback(
    (id) => {
      withReactContent(Swal)
        .fire({
          title: "",
          html: "<b>Do you want to edit address?</b>",
          confirmButtonText: "Yes",
          showCancelButton: true,
          cancelButtonText: "No",
        })
        .then((result) => {
          navigate("/update-address", { state: { key: index } });
        });
    },
    [index, navigate]
  );

  const setDefault = useCallback(
    (target) => {
      if (target.checked) {
        setAddress(address).then((data) => {
          getAddress(address?.customer_id);
        });
      }
    },
    [address, getAddress, setAddress]
  );

  return (
    <div className="card mb-1">
      <div className="card-body">
        <p
          dangerouslySetInnerHTML={{
            __html: address?.type ? "<b>" + address?.type + "</b>" : "",
          }}
        ></p>
        {address?.customer_name ? address?.customer_name + ", " : ""}
        {address?.phone ? address?.phone + ", " : ""}
        {address?.email ? address?.email + ", " : ""}
        {address?.address ? address?.address + ", " : ""}
        {address?.landmark ? address?.landmark + ", " : ""}
        {address?.city ? address?.city + ", " : ""}
        {address?.state ? address?.state + ", " : ""}
        {address?.pincode ? address?.pincode : ""}
      </div>
      <div className="card-footer text-muted">
        <div className="row align-items-center">
          <div className="col-6 col-md-4 col-lg-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
                onChange={(e) => setDefault(e.target)}
                checked={address?.selected ? "checked" : ""}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Select this address
              </label>
            </div>
          </div>
          <div
            className="col-3 col-md-4 col-lg-4 text-center"
            onClick={() => confirmEdit(address?.id)}
          >
            <i className="fa fa-edit fs-5 text-warning"></i>
          </div>
          <div
            className="col-3 col-md-4 col-lg-4 text-end"
            onClick={() => confirmDelete(address?.id)}
          >
            <i className="fa fa-trash fs-5 text-danger"></i>
          </div>
        </div>
      </div>
    </div>
  );
});
