import { Formik } from "formik";
import React, { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useRegisterCustomerMutation } from "../Store/api/AuthApi";

const SignupSchema = Yup.object().shape({
  customer_name: Yup.string()
    .min(4, "Enter full name.")
    .max(50, "Full name is too long.")
    .required("Name is required."),
  phone_number: Yup.number()
    .min(10, "Invalid mobile number.")
    .required("Mobile number is required."),
  password: Yup.string()
    .min(4, "Password is too small.")
    .required("Password is required."),
  email: Yup.string()
    .email("Please enter valid email.")
    .required("Email is required."),
});

export default function Register() {
  const navigate = useNavigate();
  const [regCustomer] = useRegisterCustomerMutation();
  useEffect(() => {
    document.body.style.overflow = "auto";
    document.body.classList.add("space-pt--70");
    document.body.classList.remove("space-pt--120");
    return function cleanup() {
      document.body.classList.remove("space-pt--70");
    };
  }, []);

  const onClose = useCallback(() => {
    navigate("/login", { replace: true });
  }, [navigate]);

  return (
    <>
      {/* auth page header */}
      <div className="auth-page-header space-mb--50">
        <div className="container">
          <div className="row">
            <div className="col-10">
              <h3 className="auth-page-header__title">Welcome</h3>
              <p className="auth-page-header__text">
                Don't have account? <br /> Please sign up for creating a new
                account.
              </p>
            </div>
            <div className="col-2 text-end fs-4">
              <i className="fa fa-times" onClick={onClose}></i>
            </div>
          </div>
        </div>
      </div>
      {/* auth page body */}
      <div className="auth-page-body">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Auth form */}
              <div className="auth-form">
                <Formik
                  initialValues={{
                    customer_name: "",
                    phone_number: "",
                    email: "",
                    password: "",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    regCustomer(values).then((data) => {
                      setSubmitting(false);
                      navigate("/login");
                    });
                  }}
                >
                  {function ({
                    handleSubmit,
                    isValid,
                    errors,
                    touched,
                    handleChange,
                    values,
                    isSubmitting,
                  }) {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="auth-form__single-field space-mb--30">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            name="customer_name"
                            onChange={handleChange}
                            value={values.customer_name}
                            placeholder="Enter Full Name"
                            autoComplete="true"
                          />
                          {errors.customer_name && touched.customer_name && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.customer_name}
                            </small>
                          )}
                        </div>
                        <div className="auth-form__single-field space-mb--30">
                          <label htmlFor="emailAddress">Mobile Number</label>
                          <input
                            type="text"
                            name="phone_number"
                            onChange={handleChange}
                            value={values.phone_number}
                            placeholder="Enter Mobile Number"
                            autoComplete="true"
                          />
                          {errors.phone_number && touched.phone_number && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.phone_number}
                            </small>
                          )}
                        </div>
                        <div className="auth-form__single-field space-mb--30">
                          <label htmlFor="emailAddress">Email Address</label>
                          <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            placeholder="Enter Email Address"
                            autoComplete="true"
                          />
                          {errors.email && touched.email && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.email}
                            </small>
                          )}
                        </div>
                        <div className="auth-form__single-field space-mb--30">
                          <label htmlFor="password">Password</label>
                          <input
                            name="password"
                            type="password"
                            onChange={handleChange}
                            value={values.password}
                            placeholder="Enter Password"
                          />
                          {errors.password && touched.password && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.password}
                            </small>
                          )}
                        </div>
                        <div className="auth-form__single-field space-mb--40">
                          <p className="auth-form__info-text">
                            Already have an account?{" "}
                            <Link to={"/login"}>Sign in Now</Link>
                          </p>
                        </div>
                        <button
                          className="auth-form__button"
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {isSubmitting ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Sign Up"
                          )}
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
