import React, { memo, useCallback } from "react";
import { IMAGE_URL } from "../Constants/Config";
import { useDispatch } from "react-redux";
import {
  callDecrementQuantity,
  callIncrementQuantity,
  callRemoveItem,
} from "../Store/Slices/CartSlice";

const CartProduct = (props) => {
  const { product } = props;
  const dispatch = useDispatch();

  const OnIncrement = useCallback(() => {
    dispatch(callIncrementQuantity(product.product_id));
  }, [dispatch, product]);

  const OnDecrement = useCallback(() => {
    if (product.qty === 1) {
      dispatch(callRemoveItem(product.product_id));
    } else {
      dispatch(callDecrementQuantity(product.product_id));
    }
  }, [dispatch, product]);

  return (
    <div className="cart-product border-bottom--medium">
      <div className="cart-product__image">
        <img
          src={IMAGE_URL + product.image}
          className="img-fluid"
          alt={product.product_name}
        />
      </div>
      <div className="cart-product__content">
        <h3 className="title">{product.product_name}</h3>
        <span className="category">{product.company_id}</span>
        <div className="price">
          <span className="discounted-price">{"₹" + product.price}</span>{" "}
          <span className="main-price">{"₹" + product.marked_price}</span>
        </div>
      </div>
      <div className="cart-product__counter">
        <div className="cart-plus-minus bg-white">
          <div className="dec qtybutton btn p-0" onClick={OnDecrement}>
            -
          </div>
          <input
            className="cart-plus-minus-box"
            type="text"
            name="qtybutton"
            disabled=""
            value={product.qty}
            readOnly
          />
          <div className="inc qtybutton btn p-0" onClick={OnIncrement}>
            +
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CartProduct);
