import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { RootState } from '../Store/Store'

export default function Footer() {
   const cartCount = useSelector((state:RootState)=>state.cart.itemCount);
  return (
   <footer>
   <div className="footer-nav-wrapper footer-nav-wrapper--styleTwo">
     <NavLink
       to="/"
       className="footer-nav-single footer-nav-single--styleTwo"
     >
       <div className="menu-wrapper">
         <img
           src="assets/img/icons/home.svg"
           className="injectable"
           alt=""
         />
       </div>
     </NavLink>
     <NavLink
       to="/cart"
       className="footer-nav-single footer-nav-single--styleTwo"
     >
       <div className="menu-wrapper">
       {cartCount>0 && (<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white">{cartCount}</span>)}
         <img
           src="assets/img/icons/cart.svg"
           className="injectable"
           alt=""
         />
       </div>
     </NavLink>
     <NavLink
       to="/profile"
       className="footer-nav-single footer-nav-single--styleTwo"
     >
       <div className="menu-wrapper">
         <img
           src="assets/img/icons/profile.svg"
           className="injectable"
           alt=""
         />
       </div>
     </NavLink>
   </div>
 </footer>
  )
}
