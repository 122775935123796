import React, { useCallback } from "react";
import { orderStatusCssClass } from "../Constants/Config";
import {
  useCancelOrderMutation,
  useCustomerOrdersMutation,
} from "../Store/api/OrderApi";

export default function OrderCard(props) {
  const { order, custome_name, handleOrderClick } = props;

  const [cancelOrder] = useCancelOrderMutation();
  const [customerOrder] = useCustomerOrdersMutation();

  const orderItems = JSON.parse(order?.items);
  //console.log(order);

  const cssClass = orderStatusCssClass[order?.status_id];

  const handleCancelOrder = useCallback(() => {
    cancelOrder({ order_id: order?.id, slug: "cancelled_by_customer" })
      .then((res) => {
        if (res?.data?.message === "Success") {
          customerOrder({ customer_id: order?.customer_id, vendor_id: 0 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [order, cancelOrder, customerOrder]);

  return (
    <div className={`card mb-2 border-${cssClass}`}>
      <div className={`card-header text-white bg-${cssClass}`}>
        <div className="row">
          <div className="col-6">Enquiry Placed</div>
          <div className="col-6 text-end">{custome_name.toUpperCase()}</div>
        </div>
      </div>
      <div className="card-body">
        <ul className="list-group list-group-flush">
          <li className="list-group-item border-0">Order ID : {order?.id}</li>
          <li className="list-group-item border-0">
            Total Items : {orderItems.length}
          </li>
          <li className="list-group-item border-0">
            Order Value : &#8377; {order?.sub_total}
          </li>
          <li className="list-group-item border-0">
            Order Status : {order?.status}
          </li>
        </ul>
        <div className="d-flex flex-row justify-content-between">
          <div>
            {order?.status_id < 2 && (
              <button
                className="btn btn-outline-danger btn-sm"
                onClick={handleCancelOrder}
              >
                Cance Order
              </button>
            )}
          </div>
          <button
            className="btn btn-outline-success btn-sm"
            onClick={() => handleOrderClick(order)}
          >
            Order Details
          </button>
        </div>
      </div>
      <div className="card-footer text-muted">
        Order Date : {order?.created_at}
      </div>
    </div>
  );
}
