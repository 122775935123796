import React from "react";
import ReactDOM from "react-dom/client";
import { persistStore } from "redux-persist";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { store } from "./Store/Store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
    <a
      href="https://api.whatsapp.com/send?phone=+91 9584366183&amp;text=Order medicine on whatsapp."
      rel="noreferrer"
      target="_blank"
      className="position-fixed wp-right wp-bottom"
    >
      <img src={require("./assets/img/WhatsApp_icon.png")} alt="mediplus" />
    </a>
  </Provider>
);
