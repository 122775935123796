import React from "react";
import { Button, Col, Row } from "reactstrap";
import { IMAGE_URL } from "../Constants/Config";
import { useDispatch } from "react-redux";
import { callUpdateCart } from "../Store/Slices/CartSlice";
import { Link } from "react-router-dom";

export default function ProductList(props) {
  const dispatch = useDispatch();

  const addTocart = async (
    qty,
    item_id,
    item_name,
    price,
    item_image,
    unit,
    marked_price
  ) => {
    let item = {
      product_id: item_id,
      product_name: item_name,
      qty: qty,
      unit: unit,
      marked_price: marked_price,
      price_per_item: parseFloat(price),
      price: parseFloat(qty * price),
      image: item_image,
    };
    dispatch(callUpdateCart(item));
  };

  const { product } = props;
  return (
    <Row className="bg-white ms-0 me-0 border-bottom p-2 cart-product__content">
      <Col xs="4" md="2">
        <img
          src={IMAGE_URL + product.image}
          className="img-fluid"
          alt={product.product_name}
        />
      </Col>
      <Col xs="8" md="10">
        <Row>
          <Col xs="12" md="10">
            <h6>
              <Link to={"/detail"} state={{ item: product }}>
                {product.product_name}
              </Link>
            </h6>
            <p className="m-0 p-0 mb-1">{product.unit}</p>
            <p className="m-0 p-0 mb-2">{product.company}</p>
            <p className="m-0 p-0 mb-2"><b>Seller</b> : {product.store_name}</p>
          </Col>
          <Col xs="12" md="2" className="text-md-center">
            <div className="price">
              <span className="discounted-price">{"₹" + product.price}</span>{" "}
              <span className="main-price">{"₹" + product.marked_price}</span>
            </div>
            <Button
              size="sm"
              color="danger"
              outline
              onClick={() =>
                addTocart(
                  1,
                  product.id,
                  product.product_name,
                  product.price,
                  product.image,
                  product.unit,
                  product.marked_price
                )
              }
            >
              Add to cart
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
