import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

export interface UtilityState {
  isFetching: boolean;
  isLoading: boolean;
  isInternetAvailable: boolean;
  isError: boolean;
  isSuccess: boolean;
  message: string;
  isSearch:boolean;
}

const initialState: UtilityState = {
  isFetching: false,
  isLoading: false,
  isInternetAvailable: true,
  isError: false,
  isSuccess: false,
  message: null,
  isSearch:false,
};

export const UtilitySlice = createSlice({
  name: "utility",
  initialState,
  reducers: {
    callIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    callIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    callIsInternetAvailable: (state, action: PayloadAction<boolean>) => {
      state.isInternetAvailable = action.payload;
    },
    callIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
    callIsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
    },
    callMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    callIsSearch: (state, action: PayloadAction<string>) => {
      state.isSearch = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  callIsFetching,
  callIsLoading,
  callIsInternetAvailable,
  callIsError,
  callIsSuccess,
  callMessage,
  callIsSearch
} = UtilitySlice.actions;

export default UtilitySlice.reducer;
