import { Formik } from "formik";
import React, { useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useLoginCustomerMutation } from "../Store/api/AuthApi";
import { toast } from "react-toastify";
import { toastConfig } from "../Constants/Config";
import { useCustomerOrdersMutation } from "../Store/api/OrderApi";
import { useSelector } from "react-redux";
import { RootState } from "../Store/Store";

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(4, "Please enter correct password.")
    .required("Password is required."),
  phone_number: Yup.number()
    .min(10, "Please enter correct mobile number.")
    .required("Mobile number is required."),
});

export default function Login() {
  const navigate = useNavigate();
  const vendor_id = useSelector((state: RootState) => state.vendor.vendor?.id);
  const { state } = useLocation();

  const [loginCustomer] = useLoginCustomerMutation();
  const [customerOrder] = useCustomerOrdersMutation();

  useEffect(() => {
    document.body.style.overflow = "auto";
    document.body.classList.add("space-pt--70");
    document.body.classList.remove("space-pt--120");
    toast.info("Login to continue", toastConfig);
    return function cleanup() {
      document.body.classList.remove("space-pt--70");
    };
  }, []);

  const onClose = useCallback(() => {
    if (state?.r) {
      navigate(-2);
    } else {
      navigate("/", { replace: true });
    }
  }, [navigate, state]);

  return (
    <>
      {/* auth page header */}
      <div className="auth-page-header space-mb--50">
        <div className="container">
          <div className="row">
            <div className="col-10">
              <h3 className="auth-page-header__title">Welcome Back</h3>
              <p className="auth-page-header__text">Log in for best shopping</p>
            </div>
            <div className="col-2 text-end fs-4">
              <i className="fa fa-times" onClick={onClose}></i>
            </div>
          </div>
        </div>
      </div>
      {/* auth page body */}
      <div className="auth-page-body">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Auth form */}
              <div className="auth-form">
                <Formik
                  initialValues={{ phone_number: "", password: "" }}
                  validationSchema={LoginSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    loginCustomer(values).then((data) => {
                      if (data?.data?.status === 1) {
                        onClose();
                        customerOrder({
                          vendor_id,
                          customer_id: data.result?.id,
                        });
                      }
                      setSubmitting(false);
                    });
                  }}
                >
                  {function ({
                    handleSubmit,
                    isValid,
                    errors,
                    touched,
                    handleChange,
                    values,
                    isSubmitting,
                    setSubmitting,
                  }) {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="auth-form__single-field space-mb--30">
                          <label htmlFor="phone">Mobile Number</label>
                          <input
                            type="text"
                            name="phone_number"
                            onChange={handleChange}
                            value={values.phone_number}
                            placeholder="Enter Mobile Number"
                            id="phone"
                            autoComplete="true"
                          />
                          {errors.phone_number && touched.phone_number && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.phone_number}
                            </small>
                          )}
                        </div>
                        <div className="auth-form__single-field space-mb--30">
                          <label htmlFor="password">Password</label>
                          <input
                            type="password"
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                            placeholder="Enter Password"
                            id="password"
                          />
                          {errors.password && touched.password && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.password}
                            </small>
                          )}
                        </div>
                        <div className="row">
                          <div className="auth-form__single-field space-mb--40 col-6">
                            <p className="auth-form__info-text">
                              Don't have an account?{" "}
                              <Link to={"/register"} replace>
                                Sign up Now
                              </Link>
                            </p>
                          </div>
                          <div className="col-6 text-end">
                            <p className="auth-form__info-text">
                              <Link to={"/forgot-password"}>
                                Forgot Password?
                              </Link>
                            </p>
                          </div>
                        </div>
                        <button
                          className="auth-form__button"
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {isSubmitting ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Login"
                          )}
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Loading block={true} /> */}
    </>
  );
}
