export const BASE_URL = "https://medical.znatic.com/";
//export const BASE_URL = "http://127.0.0.1:8000/";
export const API_URL = BASE_URL + "api/";
export const IMAGE_URL = BASE_URL + "uploads/";

export const VENDOR_PROFILE_URL = "vendor/detail";
export const LOGIN_URL = "customer/login";
export const REGISTER_URL = "customer/register";
export const VENDOR_PRODUCT_URL = "customer/vendor_products";
export const FORGOT_PASSWORD_URL = "customer/forget_password";
export const VERIFY_OTP_URL = "customer/verify_otp";
export const RESET_PASSWORD_URL = "customer/reset_password";
export const ADD_ADDRESS_URL = "customer/add_address";
export const UPDATE_ADDRESS_URL = "customer/update_address";
export const ALL_ADDRESS_URL = "customer/all_addresses";
export const SET_ADDRESS_URL = "customer/set_address";
export const DELETE_ADDRESS_URL = "customer/delete_address";
export const GET_ADDRESS_URL = "customer/edit_address";
export const PLACE_ORDER_URL = "customer/pharmacy_order";
export const GET_ORDERS_URL = "customer/get_order_list";
export const UPLOAD_PROFILE_URL = "customer/profile_picture";
export const UPDATE_PROFILE_URL = "customer/profile_picture_update";
export const GET_PROFILE_URL = "customer/get_profile";
export const CUSTOMER_UPDATE_PROFILE_URL = "customer/profile_update";
export const UPLOAD_PRESCRIPTION_IMAGE_URL =
  "customer/upload_prescription_image";
  export const UPDATE_ORDER_STATUS_URL = 'order_status_change';
export const POST_CONTACT_URL = "customer/contact";
export const GET_DATA_URL = "get_data";

export const toastConfig = {
  position: "top-right",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: "light",
  limit: 1,
};

export const orderStatusCssClass = {
  1: "warning",
  2: "warning",
  3: "warning",
  4: "warning",
  5: "warning",
  6: "success",
  9: "danger",
  10: "danger",
  11: "danger",
};
