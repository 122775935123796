import React, { useCallback, useState } from "react";
import TopAndSideBar from "../components/TopAndSideBar";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../Store/Store";
import OrderCard from "../components/OrderCard";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { IMAGE_URL } from "../Constants/Config";

export default function OrderHistory() {
  const [modal, setModal] = useState(false);
  const [fullOrder, setOrder] = useState(null);
  const Orders = useSelector((state: RootState) => state.order.customerOrder);
  const customerName = useSelector(
    (state: RootState) => state.auth.profile.customer_name
  );

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal, setModal]);

  const handleOrderClick = useCallback(
    (order) => {
      setOrder(order);
      toggle();
    },
    [setOrder,toggle]
  );

  const renderOrderItems = useCallback(() => {
   return Orders.map((order, i) => {
     return (
       <OrderCard
         order={order}
         key={i}
         custome_name={customerName}
         handleOrderClick={handleOrderClick}
       />
     );
   });
 }, [Orders, customerName, handleOrderClick]);

  const orderItemlength = useCallback(() => {
    if (fullOrder) {
      const orderItems = JSON.parse(fullOrder?.items);
      return orderItems;
    }
    return [];
  }, [fullOrder]);

  const orderPrescription = useCallback(() => {
    if (fullOrder) {
      const orderPrescription = JSON.parse(fullOrder?.prescription);
      return orderPrescription;
    }
    return [];
  }, [fullOrder]);

  return (
    <>
      <TopAndSideBar />
      <div className="breadcrumb-area space-pt--5 space-pb--25">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title">Enquiry Orders</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="auth-page-body">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">{renderOrderItems()}</div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        isOpen={modal}
        fullscreen={"sm"}
        toggle={toggle}
        size="lg"
        centered={true}
        scrollable={true}
        fade
      >
        <ModalHeader toggle={toggle}>
          Order No. {fullOrder?.id} Details
        </ModalHeader>
        <ModalBody>
          <div className="card mb-2">
            <div className="card-header">
              <h6 className="card-title mb-0">Customer Details</h6>
            </div>
            <div className="card-body py-2">
              {fullOrder?.customer_name +
                ", " +
                fullOrder?.address +
                ", " +
                fullOrder?.landmark +
                ", " +
                fullOrder?.city +
                ", " +
                fullOrder?.state +
                ", " +
                fullOrder?.pincode}
            </div>
          </div>

          <div className="card mb-2">
            <div className="card-header">
              <h6 className="card-title mb-0">Order Details</h6>
            </div>
            <div className="card-body py-2">
              <li className="list-group-item border-0">
                Order ID : {fullOrder?.id}
              </li>
              <li className="list-group-item border-0">
                Order Date : {fullOrder?.created_at}
              </li>
              <li className="list-group-item border-0">
                Total Items : {orderItemlength().length}
              </li>
              <li className="list-group-item border-0">
                Order Value : &#8377; {fullOrder?.sub_total}
              </li>
              <li className="list-group-item border-0">
                Order Status : {fullOrder?.status}
              </li>
              {orderPrescription().length > 0 && (
                <li className="list-group-item border-0">
                  Prescription :{" "}
                  {orderPrescription().map((v, i) => {
                    return (
                      <>
                        <a
                          className="text-primary"
                          href={IMAGE_URL + v}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {v}
                        </a>
                        <br />
                      </>
                    );
                  })}
                </li>
              )}

              {/* {orderPrescription().length > 0 && (<li className="list-group-item border-0">)
               Order Status : 
               { orderPrescription().map((v, i) => {
                  return (
                    
                  );
                })}

              </li>)} */}
              {/* orderPrescription().map((v, i) => {
                  return (
                    
                  );
                })} */}
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h6 className="card-title mb-0">Order Items</h6>
            </div>
            <div className="card-body py-2">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {orderItemlength().length > 0 &&
                    orderItemlength().map((v, i) => {
                      return (
                        <tr>
                          <td>{v.product_name}</td>
                          <td>{v.qty}</td>
                          <td>{v.price_per_item}</td>
                          <td>{v.price}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
