import { Formik } from "formik";
import React from "react";
//import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { usePostContactMutation } from "../Store/api/AuthApi";
//import { toast } from "react-toastify";
//import { toastConfig } from "../Constants/Config";
import TopAndSideBar from "../components/TopAndSideBar";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../Store/Store";
import { useNavigate } from "react-router-dom";

const contactSchema = Yup.object().shape({
  contact_name: Yup.string().required("Name is required."),
  contact_mobile: Yup.number()
    .min(10, "Please enter correct mobile number.")
    .required("Mobile number is required."),
});

export default function Contact() {
  const navigate = useNavigate();
  const customer = useSelector((state: RootState) => state.auth.profile);
  //const vendor_id = useSelector((state: RootState) => state.vendor.vendor.id);
  const vendor_id = 0;
  const [postContact] = usePostContactMutation();
  return (
    <>
      <TopAndSideBar />
      <div className="breadcrumb-area space-pt--5 space-pb--25">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="page-title">Contact Us</h1>
              <p>Submit your details, we will contact you soon.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="auth-page-body">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Auth form */}
              <div className="auth-form">
                <Formik
                  initialValues={{
                    vendor_id: vendor_id,
                    customer_id: customer?.id,
                    contact_mobile: customer?.phone_number,
                    contact_name: customer?.customer_name,
                  }}
                  validationSchema={contactSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    postContact(values).then((data) => {
                      setSubmitting(false);
                      navigate(-1);
                    });
                  }}
                >
                  {function ({
                    handleSubmit,
                    isValid,
                    errors,
                    touched,
                    handleChange,
                    values,
                    isSubmitting,
                    setSubmitting,
                  }) {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="auth-form__single-field space-mb--30">
                          <label htmlFor="contact_name">Customer Name</label>
                          <input
                            type="text"
                            name="contact_name"
                            onChange={handleChange}
                            value={values.contact_name}
                            placeholder="Enter Your name"
                            id="contact_name"
                            autoComplete="false"
                          />
                          {errors.contact_name && touched.contact_name && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.contact_name}
                            </small>
                          )}
                        </div>
                        <div className="auth-form__single-field space-mb--30">
                          <label htmlFor="phone">Mobile Number</label>
                          <input
                            type="text"
                            name="contact_mobile"
                            onChange={handleChange}
                            value={values.contact_mobile}
                            placeholder="Enter Mobile Number"
                            id="phone"
                            autoComplete="false"
                          />
                          {errors.contact_mobile && touched.contact_mobile && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.contact_mobile}
                            </small>
                          )}
                        </div>
                        <button
                          className="auth-form__button"
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {isSubmitting ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
