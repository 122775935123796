import React, { useEffect } from "react";
import Footer from "../components/Footer";
import TopAndSideBar from "../components/TopAndSideBar";
import { Link } from "react-router-dom";
import { useGetAddressMutation } from "../Store/api/AuthApi";
import AddressItem from "../components/AddressItem";
import { useSelector } from "react-redux";
import { RootState } from "../Store/Store";

export default function Address() {
  const [getAddress] = useGetAddressMutation();
  const customer_id = useSelector((state: RootState) => state.auth.profile.id);
  const addressList = useSelector((state: RootState) => state.auth.addresses);
  useEffect(() => {
    getAddress(customer_id);
  }, [getAddress, customer_id]);

  return (
    <>
      <TopAndSideBar />
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area space-pt--5 mb-2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6">
              <h1 className="page-title">Address</h1>
            </div>
            <div className="col-6">
              <div className="text-end">
                <Link
                  to={"/add-address"}
                  className="btn btn-outline-success btn-sm"
                >
                  <i className="fa fa-plus"></i> Address
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cart-product-area container">
        {addressList ? (
          addressList.map((address, i) => (
            <AddressItem address={address} key={i} index={i}/>
          ))
        ) : (
          <>No Address</>
        )}
      </div>
      <Footer />
    </>
  );
}
