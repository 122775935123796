import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

export interface OrderItem {
  id: number;
  prescription: string;
  address: string;
  landmark: string;
  state: string;
  city: string;
  pincode: string;
  total: 100;
  sub_total: 100;
  status: string;
  items: string;
  status_type: string;
  created_at: string;
  status_id: number;
}

export interface OrderState {
  orderData: {
    type: number,
    prescription: Array<string>,
    prescription_note: string,
    address_id: number,
    pickup_date: string,
    order_note: string,
  };
  customerOrder: Array<OrderItem>;
}

const initialState: OrderState = {
  orderData: {
    type: null,
    prescription: [],
    prescription_note: "",
    address_id: null,
    pickup_date: null,
    order_note: null,
  },
  customerOrder: [],
};

export const OrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    callUpdateOrder: (state, action: PayloadAction<OrderStates>) => {
      if (action.payload?.prescription) {
        action.payload.prescription = [
          ...state.orderData.prescription,
          action.payload?.prescription,
        ];
      }
      state.orderData = { ...state.orderData, ...action.payload };
    },
    callClearOrder: (state) => {
      state.orderData = {
        type: null,
        prescription: [],
        prescription_note: "",
        address_id: null,
        pickup_date: null,
        order_note: null,
      };
    },
    callCustomerOrder: (state, action: PayloadAction<Array<OrderItem>>) => {
      state.customerOrder = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { callUpdateOrder, callClearOrder, callCustomerOrder } = OrderSlice.actions;

export default OrderSlice.reducer;
