import React from "react";
import { IMAGE_URL } from "../Constants/Config";

export default function BrandComponent(props) {
  const { brand } = props;
  return (
    <div className="category-item">
      <div className="category-item__image d-flex">
        <img width={96} src={IMAGE_URL + brand?.image} className="injectable" alt={brand?.company} />
      </div>
      <div className="category-item__title">{brand?.company}</div>
    </div>
  );
}
