import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  API_URL,
  GET_ORDERS_URL,
  PLACE_ORDER_URL,
  toastConfig,
  UPDATE_ORDER_STATUS_URL,
  UPLOAD_PRESCRIPTION_IMAGE_URL,
} from "../../Constants/Config";
import {
  callClearOrder,
  callCustomerOrder,
  callUpdateOrder,
} from "../Slices/OrderSlice";
import { toast } from "react-toastify";
import { callClearCart } from "../Slices/CartSlice";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    uploadPrescription: builder.mutation({
      query: function (data) {
        return {
          url: UPLOAD_PRESCRIPTION_IMAGE_URL,
          method: "POST",
          body: data,
        };
      },
      onQueryStarted: async (arg, api) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          dispatch(
            callUpdateOrder({
              prescription: data.result.prescription,
            })
          );
        }
      },
    }),
    placeOrder: builder.mutation({
      query: function (data) {
        return {
          url: PLACE_ORDER_URL,
          method: "POST",
          body: data,
        };
      },
      onQueryStarted: async (arg, api) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error(data.message, toastConfig);
        } else {
          dispatch(callClearOrder());
          if (arg?.type === 1) {
            dispatch(callClearCart());
          }
        }
      },
    }),
    customerOrders: builder.mutation({
      query: function (data) {
        return {
          url: GET_ORDERS_URL,
          method: "POST",
          body: data,
        };
      },
      onQueryStarted: async (arg, api) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status === 1) {
          dispatch(callCustomerOrder(data.result));
        }
      },
    }),
    cancelOrder: builder.mutation({
      query: function (data) {
        return {
          url: UPDATE_ORDER_STATUS_URL,
          method: "POST",
          body: data,
        };
      },
      onQueryStarted: async (arg, api) => {
        const { queryFulfilled } = api;
        const { data } = await queryFulfilled;
        if (data.status !== 1) {
          toast.error("Error in user registration.", toastConfig);
        } else {
          toast.success("Order cancel successfully.", toastConfig);
        }
      },
    }),
  }),
});

export const {
  useUploadPrescriptionMutation,
  usePlaceOrderMutation,
  useCustomerOrdersMutation,
  useCancelOrderMutation,
} = orderApi;
