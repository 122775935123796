import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useResetPasswordMutation } from "../Store/api/AuthApi";

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Please enter correct password.")
    .required("Password is required."),
  retype_password: Yup.string()
    .required("Please retype your password.")
    .oneOf([Yup.ref("password")], "Your passwords do not match."),
});

export default function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(null);

  console.log(location);
  console.log(mobile);

  const [resetPassword] = useResetPasswordMutation();

  useEffect(() => {
    if (location?.state?.id) {
      setMobile(location?.state?.id);
    }
  }, [location]);

  useEffect(() => {
    document.body.classList.add("space-pt--70");
    document.body.classList.remove("space-pt--120");
    return function cleanup() {
      document.body.classList.remove("space-pt--70");
    };
  }, []);

  const onClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onResetPassword = useCallback(
    (values, setSubmitting) => {
      values.id = mobile;
      resetPassword(values).then((data) => {
        setSubmitting(false);
        if (data?.data?.status === 1) {
          navigate(-2, { replace: true });
        }
      });
    },
    [mobile, resetPassword, navigate]
  );

  return (
    <>
      {/* auth page header */}
      <div className="auth-page-header space-mb--50">
        <div className="container">
          <div className="row">
            <div className="col-10">
              <h3 className="auth-page-header__title">Welcome Back</h3>
              <p className="auth-page-header__text">Log in for best shopping</p>
            </div>
            <div className="col-2 text-end fs-4">
              <i className="fa fa-times" onClick={onClose}></i>
            </div>
          </div>
        </div>
      </div>
      {/* auth page body */}
      <div className="auth-page-body">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Auth form */}
              <div className="auth-form">
                <Formik
                  initialValues={{
                    password: "",
                    retype_password: "",
                    id: mobile,
                  }}
                  validationSchema={ResetPasswordSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    onResetPassword(values, setSubmitting);
                    //   resetPassword(values).then((data) => {
                    //     if (data.status === 1) {
                    //       navigate(-2, { replace: true });
                    //     }
                    //     setSubmitting(false);
                    //     //onClose();
                    //   });
                  }}
                >
                  {function ({
                    handleSubmit,
                    isValid,
                    errors,
                    touched,
                    handleChange,
                    values,
                    isSubmitting,
                    setSubmitting,
                  }) {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="auth-form__single-field space-mb--30">
                          <label htmlFor="password">New password</label>
                          <input
                            type="password"
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                            placeholder="New password"
                            id="password"
                          />
                          {errors.password && touched.password && (
                            <small
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.password}
                            </small>
                          )}
                        </div>
                        <div className="auth-form__single-field space-mb--30">
                          <label htmlFor="retype_password">
                            Retype new password
                          </label>
                          <input
                            type="password"
                            name="retype_password"
                            onChange={handleChange}
                            value={values.retype_password}
                            placeholder="Retype new password"
                            id="retype_password"
                          />
                          {errors.retype_password &&
                            touched.retype_password && (
                              <small
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {errors.retype_password}
                              </small>
                            )}
                        </div>
                        <button
                          className="auth-form__button"
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {isSubmitting ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Reset password"
                          )}
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Loading block={true} /> */}
    </>
  );
}
