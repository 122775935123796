import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toastConfig } from "../../Constants/Config";

export interface cartItem {
  product_id: number;
  product_name: string;
  qty: number;
  unit: string;
  price_per_item: number;
  price: number;
  image: string;
}

export interface CartState {
  cartItems: Array<cartItem>;
  subTotal: number;
  itemCount: number;
}

const initialState: CartState = {
  cartItems: [],
  subTotal: 0.0,
  itemCount: 0,
};

export const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    callUpdateCart: (state, action: PayloadAction<cartItem>) => {
      const itemInCart = state.cartItems?.find(function (item) {
        return item.product_id === action.payload.product_id;
      });
      if (itemInCart) {
        itemInCart.qty++;
      } else {
        state.cartItems.push(action.payload);
      }
      state.subTotal += parseFloat(
        action.payload.qty * action.payload.price_per_item
      );
      state.itemCount++;
      toast.success("Item added in cart", toastConfig);
    },
    callIncrementQuantity: (state, action) => {
      const item = state.cartItems.find(
        (item) => item.product_id === action.payload
      );
      item.qty++;
      state.subTotal += parseFloat(item.price_per_item);
      state.itemCount++;
    },
    callDecrementQuantity: (state, action) => {
      const item = state.cartItems.find(
        (item) => item.product_id === action.payload
      );
      if (item.qty === 1) {
        item.qty = 1;
      } else {
        item.qty--;
        state.subTotal -= parseFloat(item.price_per_item);
        state.itemCount--;
      }
    },
    callRemoveItem: (state, action) => {
      const index = state.cartItems.findIndex(
        (item) => item.product_id === action.payload
      );

      if (index >= 0) {
        state.itemCount -= state.cartItems[index].qty;
        state.subTotal -=
          state.cartItems[index].qty * state.cartItems[index].price_per_item;
        state.cartItems.splice(index, 1);
        toast.success("Item removed", toastConfig);
      }
    },
    callClearCart: (state) => {
      state.cartItems = [];
      state.subTotal = 0.0;
      state.itemCount = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  callUpdateCart,
  callIncrementQuantity,
  callDecrementQuantity,
  callRemoveItem,
  callClearCart,
} = CartSlice.actions;

export default CartSlice.reducer;
